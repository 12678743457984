.focusPage {

  padding: 0;
  margin: 0;
  text-align: left;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: #0d6efd;
    border-bottom: 1px solid #0d6efd;

    h3 {
      padding-left: 1rem;
      margin: 0;
    }
  }

  .action {
    margin-left: auto;
  }

  .body {
    padding: 1rem;
  }

  .proSettings {
    padding-top: 1rem;
  }
}

.actionButtons {

  width: 100%;
  padding: 2rem;

  button {
    display: block;
    margin: 1.2rem auto;
    font-size: 1.2rem;
  }
}
