.photo button span {
  padding-left: 10px;
}

.photo .preview img {
  width: 50%;
}

.photo .preview button {
  margin-top: 20px;
}

.photo .react-html5-camera-photo  {
  width: 50%;
  margin: auto;
}
