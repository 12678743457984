.feedDetail {
  font-size: 0.875rem;
  /* line-height: 1.5em; */

  .proSettingsContainer {
    padding-top: 1.5em;
  }
}

.feedDetail .label {
  font-size: 0.8rem;
  color: #aaa;
  text-transform: uppercase;
  display: block;
  letter-spacing: .1rem;
}

.feedDetail .value {
  margin: 5px 0 10px 0;
}

.feedDetail .rating {
  margin-left: 10px;
}

.feedDetail .imageContainer {
  margin-top: 20px;
}

.feedDetail .comment {
  white-space: pre-wrap;
}

.feedDetail {
  .container.button {
    padding: 1rem;
    text-align: center;
  }
}
