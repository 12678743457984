.coffeeDetail {
  font-size: 0.875rem;
}

.coffeeDetail .label {
  font-size: 0.8rem;
  color: #aaa;
  text-transform: uppercase;
  display: block;
  letter-spacing: .1rem;
}

.coffeeDetail .value {
  margin: 5px 0 10px 0;
}

.coffeeDetail .rating {
  margin-left: 10px;
}

.coffeeDetail .imageContainer {
  margin-top: 20px;
}

.coffeeDetail .nav-link {
  text-decoration: underline;
  text-underline-offset: .3rem;
}

.coffeeDetail .comment {
  white-space: pre-wrap;
}
